import React,{useState} from 'react'
import {Button, Form, Row} from 'react-bootstrap'
import call_apis from '../../services/Apis'


const ForgetPassword = () => {
	
	const [email, SetEmail] = useState("")

	const handleChange = (e) => {
		console.log("handleChange", e.target.value)
		SetEmail(e.target.value)
	}
	
	const ForgotSubmit = async(email) =>{
		//e.defaultPrevent();
		alert("data")
		
		console.log("email", email)

		const forgetdata = await call_apis.forgetPassword(email);
		console.log("forgetdata", forgetdata)
		

	}

  
  return (
		<>
			<Row>
				<div className="title">Reset Password</div>
				<div className="subHeading">
					Please fill the form to get your password
				</div>
				<Form className="forgotForm" >
					<Form.Group className="mb-3" onBlur={handleChange} >
						<input style={{ width: '100%', height: '32px' }} placeholder="Email"/>
					</Form.Group>
					<Button className="logInBtn w-100" onClick={() => ForgotSubmit(email)}>
						Send Reset Email
					</Button>
				</Form>
			</Row>
			
		</>
  )
}

export default ForgetPassword
